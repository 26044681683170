import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { NumericFormat } from "react-number-format";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const ProgressBar = ({ progressPercentage, color }) => {
  return (
    <div className="h-2 rounded-xl w-full bg-gray-300">
      <div
        style={{ width: `${progressPercentage * 20}%`, backgroundColor: color }}
        className="h-full rounded-xl"
      ></div>
    </div>
  );
};

function Dashboard() {
  const [slides, setSlides] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [salaries, setSalaries] = useState([]);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [date, setDate] = useState(dayjs());
  const sliderRef = useRef(null);

  const user_id = localStorage.getItem("user_id");

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase();
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      window.location.href = "/login";
    } else {
      (async () => {
        try {
          const response = await axios.get(
            `https://nextsteps-api-11571fb59487.herokuapp.com/api/companies/${user_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );
          setSlides(response.data.slides);
          setCandidates(response.data.candidates);
          setSalaries(response.data.salaries);
        } catch (e) {
          window.location.href = "/login";
        }
      })();
    }
  }, [user_id]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    const currentSlide = sliderRef.current.swiper.realIndex;
    const numberSlides = sliderRef.current.swiper.slides.length;
    const secondToLastSlide = numberSlides - 1;
    if (currentSlide === secondToLastSlide) {
      sendLead(email);
    }
  }, [email]);

  const sendLead = async (email) => {
    const data = {
      email: email,
      user_id: localStorage.getItem("user_id"),
      meeting_date: date.format("YYYY-MM-DD HH:mm"),
    };

    console.log(data);
    await axios
      .post(`https://nextsteps-api-11571fb59487.herokuapp.com/api/lead/save/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          setErrorMessage(null);
          return true;
        }
      })
      .catch((e) => {
        console.log(e)
        setErrorMessage(e.response?.data?.email[0]);
        return false;
      });
  };

  const colors = [
    "#4f9d4f",
    "#3876ad",
    "#4da3bc",
    "#dd5d10",
    "#c99644",
    "#71dd10",
    "#101edd",
    "#b94844",
    "#dd10b0",
  ];

  return (
    <section className="bg-white p-5 flex">
      <div className="w-[100%]">
        {slides && slides.length > 0 ? (
          <>
            <Swiper ref={sliderRef}>
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <p className="font-medium text-3xl bg-cyan-700 text-white p-4 mb-4">
                    {slide.title}
                  </p>
                  <div
                    className="text-slate-700"
                    dangerouslySetInnerHTML={{
                      __html: slide.content,
                    }}
                  />
                </SwiperSlide>
              ))}
              {candidates.map((candidate, index) => (
                <SwiperSlide key={index}>
                  <div className="flex gap-3 mb-5">
                    <div className="px-3 justify-between py-4 flex flex-col w-[32%]">
                      <div className="flex flex-col">
                        <img
                          src={candidate.photo}
                          alt={candidate.first_name}
                          width={200}
                          height={200}
                        />
                      </div>
                    </div>

                    <div className="px-3 justify-between py-4 flex flex-col w-[62%]">
                      <div className="flex flex-col">
                        <p className="font-medium text-3xl bg-cyan-700 text-white p-4 mb-4">
                          {candidate.first_name}{" "}
                          {capitalize(candidate.last_name)}
                        </p>
                        <ul className="mb-2">
                          {candidate.skills.map((skill, index) => (
                            <li key={index} className="mb-3">
                              {}
                              <ProgressBar
                                progressPercentage={skill.level}
                                color={
                                  colors[
                                    Math.floor(Math.random() * colors.length)
                                  ]
                                }
                              />
                              {skill.name}
                            </li>
                          ))}
                        </ul>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: candidate.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}

              {salaries.map((salary, index) => (
                <SwiperSlide key={index} className="text-center">
                  <div
                    className="justify-between py-4 flex flex-col text-lg text-blue-600 font-bold"
                    dangerouslySetInnerHTML={{
                      __html: salary.content,
                    }}
                  />
                  <p className="font-medium text-slate-500 text-3xl p-4">
                    Current Salary:
                  </p>
                  <p className="text-5xl">
                    <NumericFormat
                      value={salary.current_salary.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </p>
                  <p className="font-medium text-slate-500 text-xl mb-4">
                    Per Year
                  </p>
                  <hr />
                  <p className="font-medium text-slate-500 text-3xl p-4">
                    Desired Salary:
                  </p>
                  <p className="text-5xl">
                    <NumericFormat
                      value={salary.desired_salary.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </p>
                  <p className="font-medium text-slate-500 text-xl mb-4">
                    Per Year
                  </p>
                  <hr />
                  <p className="font-medium text-slate-500 text-3xl p-4">
                    Our Fee:
                  </p>
                  <p className="text-5xl">
                    <NumericFormat
                      value={salary.fee.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </p>
                  <p className="font-medium text-slate-500 text-xl mb-4">
                    One Time Fee ONLY Upon Hire
                  </p>

                  <div className="justify-between py-4 flex flex-col text-lg text-blue-600 font-bold">
                    <p className="font-medium p-4 mb-12">
                      Click next to schedule a call with us
                    </p>
                  </div>
                </SwiperSlide>
              ))}
 
              <SwiperSlide className="text-center">
                <p className="font-medium text-3xl bg-cyan-700 text-white p-4 mb-4">
                  Schedule a Call
                </p>
                <div className="justify-between py-4 flex flex-col text-lg font-bold mb-10">
                  Please select a date and time when you would like to schedule
                  a call with us.
                </div>
                <DateTimePicker
                  label="Schedule a Call With Us"
                  value={date}
                  timezone="America/Toronto"
                  onChange={(newValue) => setDate(newValue)}
                />
              </SwiperSlide>
              <SwiperSlide className="text-center">
                <p className="font-medium text-3xl bg-cyan-700 text-white p-4 mb-4">
                  NO OBLIGATION service agreement
                </p>
                <div className="justify-between py-4 flex flex-col text-lg font-bold mb-10">
                  Enter your email below to receive our NO OBLIGATION service
                  agreement that outlines the brief terms in order to proceed
                </div>
                <p className="justify-between flex flex-col mb-10 underline">
                  Remeber, you don't pay ANY fee's unless a hire is made.
                </p>
                <p className="justify-between flex flex-col text-lg font-bold">
                  Agreeing to the terms in our NO OBLIGATION service agreement
                  protects the privacy of our candidates information and advises
                  of the fees due ONLY upon a successful hire.
                </p>

                <div className="mt-10 text-lg flex flex-col justify-between rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col items-center space-y-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-green-600 w-28 h-28"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h1 className="text-4xl font-bold">Thank You !</h1>
                  <p>
                    Thanks for working with us. You will receive an email with
                    your Service Agreement shortly.
                  </p>
                  <p>
                    If you have any questions, please contact Chris De Sousa at
                    chris@nextstepz.com
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>

            <div>
              <div className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded-full hover:bg-indigo-700 focus:outline-none focus:ring">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
                <button
                  onClick={handlePrev}
                  className="hover:text-blue-900 text-sm font-medium"
                >
                  Go Back
                </button>
              </div>
              <button
                onClick={handleNext}
                className="font-medium py-3 float-right ml-auto rounded-full hover:text-white bg-blue-950 text-sm text-white w-[110px]"
              >
                Next Step
              </button>
            </div>
          </>
        ) : (
          <p>
            There are no slides currently. Please log in to the admin to add
            some.
          </p>
        )}
      </div>
    </section>
  );
}

export default Dashboard;
